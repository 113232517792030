<template>
  <v-dialog
      v-model="dialog"
      max-width="700"
      scrollable
  >
    <v-card>
      <v-toolbar
          dark>
        <v-card-title>

          <span>{{ title }} instrument family</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined
                  dense
                  :error="$v.instrument.name.$error"
                  v-model="instrument.name"
              >
                <template v-slot:label>
                  Instrument family title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
              <span class="text-danger" v-if="$v.instrument.name.$error">Name is required</span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-select @change="changeCategory" v-model="instrument.category_id" :items="categories"
                  outlined item-text="name" item-value="id"
                  :error="$v.instrument.category_id.$error" dense>
                <template v-slot:label>
                  Category <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.category_id">{{ errors.category_id[0] }}</span>
              <span class="text-danger" v-if="$v.instrument.category_id.$error">Category is required</span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <label>Description</label>
              <span class="text-danger">*</span> 
              <ckeditor
                  :config="editorConfig"
                  :error="$v.instrument.description.$error"
                  v-model="instrument.description" >
              </ckeditor>
              <span class="text-danger" v-if="errors.description">{{ errors.description[0] }}</span>
              <span class="text-danger" v-if="$v.instrument.description.$error">This information is required</span>
            </v-col>


            <v-col cols="12" sm="12">
              <div class="text-body">Grades</div>
              <v-select item-text="name" item-value="id" :error="$v.instrument.applicable_grades.$error"
                  v-model="instrument.applicable_grades" :items="grades"
                  multiple small-chips deletable-chips outlined dense>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allSelectGrades"
                  >
                    <v-list-item-action>
                      <v-icon :color="instrument.applicable_grades > 0 ? 'indigo darken-4' : ''">
                        {{ iconGrade }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.applicable_grades">{{ errors.applicable_grades[0] }}</span>
              <span class="text-danger" v-if="$v.instrument.applicable_grades.$error">This information is required</span>
            </v-col>

            <v-col cols="12" v-if="currentUser.access_type == 'federal'">
              <div class="text-body">SCORE</div>
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="scores"
                  v-model="instrument.score_ids"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allSelectScores"
                  >
                    <v-list-item-action>
                      <v-icon :color="instrument.score_ids > 0 ? 'indigo darken-4' : ''">
                        {{ iconScore }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
            </v-col>

            <v-col cols="6" md="3" v-if="categoryDetail && categoryDetail.has_syllabuses">
              Syllabus
              <v-switch
                v-model="instrument.has_syllabuses"
                :label="instrument.has_syllabuses ? 'Enable':'Disable'"
                color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="3" v-if="categoryDetail && categoryDetail.has_exam_types">
              Exam type
              <v-switch
                v-model="instrument.has_exam_types"
                :label="instrument.has_exam_types ? 'Enable' : 'Disable' "
                color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="3" v-if="instrument.has_exam_types && categoryDetail && categoryDetail.has_exam_options">
              Exam option
              <v-switch
                v-model="instrument.has_exam_options"
                :label="instrument.has_exam_options ? 'Enable' : 'Disable' "
                color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="3">
              Status
              <v-switch
                  v-model="instrument.is_active"
                  :label="instrument.is_active ? 'Active' : 'Inactive' "
                  color="primary"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text x-large @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn x-large dark :loading="loading"
            @click="createOrUpdate()">
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import CategoryService from "@/services/product/category/CategoryService";
import GradeService from "@/services/product/grade/GradeService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import CreateAndUpdate from "./CreateAndUpdate";

import {required, email, requiredIf} from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";
import SettingService from "@/services/setting/SettingService";

const setting=new SettingService();
const category = new CategoryService();
const grade = new GradeService();
const instrument = new InstrumentService();
const score = new ScoreService();

export default {
  validations: {
    instrument: {
      name: {required},
      description: {required},
      category_id: {required},
      is_active: {required},
      managed_by: {},
      applicable_grades:{required}
    }
  },


  data() {
    return {
      sort: {
        key: '',
        isAsc: false
      },
      loading: false,
      title: "",
      dialog: false,
      total: null,
      perPage: null,
      page: null,
      edit: false,

      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      userTypes: [
        { name: "Federal", value: "federal" },
        { name: "Score", value: "score" },
        { name: "Both", value: "both" }
      ],
      currentUser: {},
      errors: [],
      categories: [],
      grades: [],
      scores: [],
      instrument: {
        name:'',
        description:'',
        has_syllabuses:0,
        has_exam_types:0,
        has_exam_options:0,
        category_id:null,
        score_ids: [],
        applicable_grades: [],
        is_online:false,
        managed_by: '',
        is_active:1,
        practical_result_report_options:[],
        mark_types:'',
        piece_naming_standard:'',
        general_remarks_naming_standard:'',
      },
      mark_types: [],
      practical_result_report_options:[],
      practical_result_report_value:[],
      piece_naming_standard: [],
      is_practical_flag:false,
      categoryDetail:{},
    }
  },
  methods: {
    changeCategory(){
      this.getCategoryDetail();
    },
    getCategoryDetail(){
      category
          .show(this.instrument.category_id)
          .then(response => {
            this.categoryDetail=response.data.category;
            if(this.categoryDetail && !this.categoryDetail.is_online){
              this.is_practical_flag=true;
            }else{
              this.is_practical_flag=false;
            }
          })
          .catch((err) => {

            this.$snotify.error("Oops something went wrong");
          });
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createInstrument() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },

    editInstrument(item) {
      this.openDialog();
      this.edit = true;
      this.instrument = item;
      this.title = "Edit";
      //  this.changeCategory();
      if(item.applicable_grades == null) {
        this.instrument.applicable_grades = []
      }
      if(item.score_ids == null) {
        this.instrument.score_ids = []
      }
      if(item.practical_result_report_options){
        this.practical_result_report_value=item.practical_result_report_options.split(',');
      }else{
        this.practical_result_report_value=[];
      }
      if(item && item.category_id){
        this.categoryDetail=item.category_id;
      }
      this.changeCategory();
    },
    getAllScore() {
      score.all().then(response => {
        this.scores = response.data.scores;
      });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if(this.practical_result_report_value.length >0){
          this.instrument.practical_result_report_options=this.practical_result_report_value.toString();
        }else{
          this.instrument.practical_result_report_options='';
        }
        if (!this.edit) this._create(this.instrument);
        else this.update(this.instrument);
      }
    },

    _create: function (data) {
      this.loading = true;
      instrument
          .create(data)
          .then(response => {
            this.$snotify.success("Instrument added");
            this.resetForm();
            this.loading = false;
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update: function (data) {
      this.loading = true;
      instrument
          .update(this.instrument.id, data)
          .then(response => {
            this.$snotify.success("Instrument updated");
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },

    resetForm() {
      this.openDialog();
      this.instrument = {
        name:'',
        description:'',
        has_instruments:false,
        has_syllabuses:false,
        has_exam_types:false,
        has_exam_options:false,
        category_id:null,
        score_ids: [],
        applicable_grades: [],
        is_online:false,
        managed_by: '',
        is_active:1,
        practical_result_report_options:[],
        mark_types:'',
        piece_naming_standard:'',
        general_remarks_naming_standard:'',
      }
      this.practical_result_report_value=[];
      this.$v.$reset();
    },

    getAllGrades() {
      grade
          .all()
          .then(response => {
            this.grades = response.data.grades;
          })
          .catch((err) => {
          });
    },

    getAllCategories() {
      let data={};
      data={
        'has_instruments':1
      }
      category
          .getFilterData(data)
          .then(response => {
            this.categories = response.data.categories;
          })
          .catch((err) => {

          });
    },
    searchCategory() {
      this.getAllCategories();
      this.getAllGrades();
    },

    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    allSelectScores () {
      this.$nextTick(() => {
        if (this.allSelectScore) {
          this.instrument.score_ids = []
        } else {
          this.instrument.score_ids = [];
          this.scores.forEach(score => {
            this.instrument.score_ids.push(score.id);
          });
        }
      })
    },
    allSelectGrades() {
      this.$nextTick(() => {
        if (this.allSelectGrade) {
          this.instrument.applicable_grades = []
        } else {
          this.instrument.applicable_grades = [];
          this.grades.forEach(grade => {
            this.instrument.applicable_grades.push(grade.id);
          });
        }
      })
    },
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },

    getAcceptedValueData(){
      setting
          .getAcceptedValueData()
          .then(response => {
            for(let key in response.data.practical_result_report_options){
              this.practical_result_report_options.push({value:key, text:response.data.practical_result_report_options[key]})
            }
          })
    },
    getMarkedTypeOption(){
      setting
          .getMarkedTypeOption()
          .then(response => {
            for(let key in response.data.marked_type_options){
              this.mark_types.push({value:key, text:response.data.marked_type_options[key]})
            }
          })
    },
    allAcceptedValue() {
      this.$nextTick(() => {
        if (this.allSelectAcceptedValue) {
          this.practical_result_report_value = []
        } else {
          this.practical_result_report_value = [];
          this.practical_result_report_options.forEach(value => {
            this.practical_result_report_value.push(value);
          });
        }
      })
    },
    getPieceNamingOption(){
      setting
          .getPieceNamingOption()
          .then(response => {
            for(let key in response.data.piece_naming_options){
              this.piece_naming_standard.push({value:key, text:response.data.piece_naming_options[key]})
            }
          });
    }
  },
  mounted() {
    this.getAllGrades();
    this.getAllCategories();
    this.getAllScore();
    this.getCurrentUser();
    this.getAcceptedValueData();
    this.getMarkedTypeOption();
    this.getPieceNamingOption();
  },
  computed: {
    sortedItems() {
      const list = this.categories.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }

      return list;
    },
    allSelectScore () {
      return this.instrument.score_ids.length === this.scores.length
    },
    selectScore () {
      return this.instrument.score_ids.length > 0 && !this.allSelectScore
    },
    iconScore () {
      if (this.allSelectScore) return 'mdi-close-box'
      if (this.selectScore) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allSelectGrade () {
      return this.instrument.applicable_grades.length === this.grades.length
    },
    selectGrade () {
      return this.instrument.applicable_grades.length > 0 && !this.allSelectGrade
    },
    iconGrade () {
      if (this.allSelectGrade) return 'mdi-close-box'
      if (this.selectGrade) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allSelectAcceptedValue () {
      return this.practical_result_report_value.length === this.practical_result_report_options.length;
    },
    selectAcceptValue () {
      return this.practical_result_report_value.length > 0 && !this.allSelectAcceptedValue;
    },
    iconAcceptValue () {
      if (this.allSelectAcceptedValue) return 'mdi-close-box'
      if (this.selectAcceptValue) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }

}
</script>
