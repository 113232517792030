<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Instrument families
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ <router-link to="/category">Categories </router-link> \ Families
                </div>
              </div>

              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="createInstrument()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add instrument family
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    label="Instrument family"
                    v-model="search.name"
                    outlined
                    dense
                    v-on:keyup.enter="searchInstruments()"
                    @input="search.name = $event !== null ? $event : ''"
                    clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-autocomplete
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Category"
                    clearable
                    v-model="search.category_id"
                    v-on:keyup.enter="searchInstruments()"
                    @input="search.category_id = $event !== null ? $event : ''"
                    dense
                    outlined>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2" md="2">
                  <v-select
                    :items="search_syllabuses"
                    v-model="search.has_syllabuses"
                    label="Syllabus status"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    clearable
                    v-on:keyup.enter="searchInstruments()"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2" md="2">
                  <v-select
                    :items="search_statuses"
                    v-model="search.status"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    v-on:keyup.enter="searchInstruments()"
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-btn
                    :loading="loading"
                    @click.prevent="searchInstruments()"
                    class="btn btn-primary w-35 float-right"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

              </div>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3">Family</th>
                    <th class="px-3">Category</th>
                    <th class="px-3">Syllabuses</th>
                    <th class="px-3">Exam type</th>
                    <th class="px-3">Exam options</th>
                    <th class="px-3">SCORES</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                </thead>

                <template>
                  <draggable
                    v-model="instruments"
                    @change="sortInstrument"
                    @start="drag=true"
                    tag="tbody"
                    @end="drag=false"
                  >
                    <tr v-for="(item, index) in instruments" :key="index">
                      <td class="px-3">
                        <a @click="editInstrument(item)"
                           class="text-primary font-weight-bolder text-hover-primary mb-1">
                          <i class="fa fa-sort"></i> {{ item.name }}
                        </a>
                      </td>
                      <td class="px-3">
                        <div class="">
                          {{ item.category? item.category.name:'NA' }}
                        </div>
                      </td>
                      <td class="px-3">
                        <span class="badge"
                        v-bind:class="{ 'badge-success': item.has_syllabuses, 'badge-warning': !item.has_syllabuses }"
                        >{{ item.has_syllabuses ? 'Enable' : 'Disable' }}</span>
                      </td>
                      <td class="px-3">
                        <span class="badge"
                        v-bind:class="{ 'badge-success': item.has_exam_types, 'badge-warning': !item.has_exam_types }"
                        >{{ item.has_exam_types ? 'Enable' : 'Disable' }}</span>
                      </td>
                      <td class="px-3">
                        <span class="badge"
                        v-bind:class="{ 'badge-success': item.has_exam_options, 'badge-warning': !item.has_exam_options }"
                        >{{ item.has_exam_options ? 'Enable' : 'Disable' }}</span>
                      </td>

                      <td class="px-3">
                        <ul v-if="item.score_lists">
                          <li v-for="score in item.score_lists">
                            {{ score.name }}
                          </li>
                        </ul>
                        <span v-else>
                          NA
                        </span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip>
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="currentUser.access_type=='federal'">
                                <a  class="navi-link"  @click="editInstrument(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link"  @click="deleteInstrument(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="instruments.length == 0">
                      <td class="text-center px-3" colspan="8">No items found.</td>
                    </tr>
                  </draggable>
                </template>
              </table>
              <b-pagination
                v-if="instruments.length > 0"
                class="pull-right mt-7"
                @input="getAllInstruments"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                :disabled="loading"
                last-number
              ></b-pagination>
            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                indeterminate
                color="dark"
              ></v-progress-circular>
            </v-overlay>
          </div>

        </div>
      </div>
    </div>
    <create-and-update ref="create-and-update" @refresh="getAllInstruments"></create-and-update>
  </v-app>
</template>
<script>

import InstrumentService from "@/services/product/instrument/InstrumentService";
import CategoryService from "@/services/product/category/CategoryService";
import CreateAndUpdate from "../instrument/CreateAndUpdate";
import draggable from 'vuedraggable';

const category = new CategoryService();
const instrument=new InstrumentService();
export default {
  data(){
    return{
      instruments:[],
      categories:[],
      categoryId: null,
      total: null,
      perPage: null,
      page: null,
      loading: true,
      isSorting: false,
      sort: {
        key: '',
        isAsc: false
      },
      search:{
        name:'',
        category_id:'',
        has_syllabuses: '',
        status:''
      },
      search_statuses: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      search_instruments: [
        {name: 'All', value: ''},
        {name: 'Enabled', value: 1},
        {name: 'Disabled', value: 0},
      ],

      search_syllabuses: [
        {name: 'Enabled', value: 1},
        {name: 'Disabled', value: 0},
      ],
      currentUser:{},
    }
  },
  components: {
    CreateAndUpdate, draggable
  },
  methods: {
    createInstrument() {
      this.$refs['create-and-update'].createInstrument();
    },

    editInstrument(item) {
      this.$refs['create-and-update'].editInstrument(item);
    },

    goToSyllabus(instrumentId) {
      this.$router.push({
        name: "syllabusByInstrument",
        params: { instrumentId: instrumentId }
      });
    },

    sortInstrument(){
      this.isSorting = true;
      instrument
      .sort(this.instruments)
      .then(response => {
        this.getAllInstruments();
        this.isSorting = false;
        this.$snotify.success('Instrument Sorted!!');
      })
      .catch(err => {
        this.isSorting = false;
        this.$snotify.error("Oops something went wrong");
      });
    },
    getAllInstruments(){
      instrument
      .paginate(this.search, this.page)
      .then(response => {
        this.instruments=response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      })
      .catch((err) => {
      }).finally(() => {
        this.loading = false;
      });

    },

    deleteInstrument(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            instrument
                .delete(item.id)
                .then((response) => {
                  this.loading = false;
                  this.getAllInstruments();
                  this.$snotify.success("Instrument deleted");
                })
                .catch((err) => {
                  this.loading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    sortedClass (key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc' }` : '';
    },
    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    searchInstruments(){
      this.getAllInstruments();
    },
    resetSearch(){
      this.search={
        info:'',
        title:'',
        category_id: '',
      }
    },
    getAllCategories() {
      category
          .all()
          .then(response => {
            this.categories = response.data.categories;
          })
          .catch((err) => {

          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }

  },
  mounted() {
    this.categoryId = this.$route.params.categoryId;
    if(this.categoryId != undefined && this.categoryId != null) {
      this.search.category_id = this.categoryId;
    }
    // this.getAllCategories();
    this.getCurrentUser();
    this.getAllInstruments();
  },
  computed:{
    sortedItems () {
      const list = this.instruments.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }

      return list;
    },
    // routeTab(){
    //   if(this.addressBook){
    //     let title = this.addressBook.first_name + '- Address book'
    //     return {
    //       title: title
    //     }
    //   }
    // }
  },
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>
